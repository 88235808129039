import React from 'react';
import { get } from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { RichText } from 'gatsby-theme-q3/src/components';
import VendorLogosList from './VendorLogoList';

export default () => {
  const query = useStaticQuery(graphql`
    {
      contentfulPage(
        contentful_id: { eq: "7ClE3JX9y9r3lM4fv5Xj3J" }
      ) {
        banner {
          title
          leadIn {
            raw
          }
        }
      }
    }
  `);

  const title = get(query, 'contentfulPage.banner.title');
  const desc = get(
    query,
    'contentfulPage.banner.leadIn.raw',
  );

  return (
    <Box component="section" my={8}>
      <Container>
        <Container maxWidth="xl">
          <Box textAlign="center" mb={4}>
            <Container maxWidth="md">
              <Typography
                align="center"
                variant="h1"
                component="h3"
                gutterBottom
              >
                {title}
              </Typography>
              {desc && <RichText json={JSON.parse(desc)} />}
            </Container>
          </Box>
        </Container>
        <VendorLogosList />
      </Container>
    </Box>
  );
};
