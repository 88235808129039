import React from 'react';
import PropTypes from 'prop-types';
import { get, groupBy, size, pick } from 'lodash';
import { graphql } from 'gatsby';
import Box from '@material-ui/core/Box';
import HomepageCallToActions from '../components/HomepageCallToActions';
import SEOHelmet from '../components/SEOHelmet';
import PromotionSlider from '../components/PromotionSlider';
import PromotionSliderSecondGroup from '../components/PromotionSliderSecondGroup';
import VendorLogos from '../containers/VendorLogos';

const Index = ({ data: { contentfulIndex } }) => {
  const promotions = groupBy(
    contentfulIndex.promotions,
    'placement',
  );

  const renderPromotions = (k) =>
    size(promotions[k]) ? (
      <PromotionSliderSecondGroup data={promotions[k]} />
    ) : null;

  return (
    <Box
      component="main"
      bgcolor="background.paper"
      maxWidth="100%"
      overflow="hidden"
    >
      <SEOHelmet
        title={get(contentfulIndex, 'seo.title')}
        description={get(
          contentfulIndex,
          'seo.metaDescription.metaDescription',
        )}
      />
      <Box display="flex" flexDirection="column-reverse">
        <HomepageCallToActions
          {...pick(contentfulIndex, [
            'title',
            'callsToAction',
          ])}
        />
        <PromotionSlider data={contentfulIndex.carousel} />
      </Box>
      {renderPromotions('top')}
      {renderPromotions('bottom')}
      <VendorLogos />
    </Box>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    contentfulIndex: PropTypes.shape({
      carousel: PropTypes.arrayOf(
        PropTypes.shape({
          // eslint-disable-next-line
          slides: PropTypes.array,
        }),
      ).isRequired,
      promotions: PropTypes.arrayOf(
        // eslint-disable-next-line
        PropTypes.object,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query {
    contentfulIndex {
      seo {
        title
        metaDescription {
          metaDescription
        }
      }
      callsToAction {
        image {
          fluid(maxWidth: 300) {
            ...GatsbyContentfulFluid
          }
        }
        title
        descriptionRt {
          raw
        }
        buttonText
        buttonUrl
      }
      promotions {
        backgroundColourHex
        artDirection
        backgroundColourHex
        imageBackgroundBehaviour
        bgColor
        button
        buttonUrl
        color
        image {
          fluid(maxWidth: 850) {
            ...GatsbyContentfulFluid
          }
        }
        placement
        label
        nameRt {
          raw
        }
        descriptionRt {
          raw
        }
        textSize
      }
      carousel {
        slides {
          backgroundColourHex
          artDirection
          backgroundColourHex
          imageBackgroundBehaviour
          bgColor
          button
          buttonUrl
          color
          descriptionRt {
            raw
          }
          image {
            fluid(maxWidth: 850) {
              ...GatsbyContentfulFluid
            }
          }
          nameRt {
            raw
          }
        }
      }
      title
    }
  }
`;

export default Index;
