import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Box,
  makeStyles,
  Hidden,
} from '@material-ui/core';
// eslint-disable-next-line
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
} from 'pure-react-carousel';
import { map, size } from 'lodash';
import PromotionSliderBlockLarge from './PromotionSliderBlockLarge';
// eslint-disable-next-line
import 'pure-react-carousel/dist/react-carousel.es.css';

const useStyle = makeStyles((theme) => ({
  dots: {
    bottom: '1rem',
    left: '1rem',

    '& button': {
      backgroundColor: theme.palette.primary.main,
      border: 0,
      borderRadius: '500px',
      opacity: 0.5,
      height: '1rem',
      transitionDuration: '150ms',
      transitionProperty: 'opacity,transform',
      width: '1rem',
      marginRight: '.25rem',

      '&:hover': {
        opacity: 0.7,
        transform: 'scale(1.05)',
      },

      '&:disabled': {
        cursor: 'not-allowed',
        opacity: '0.2 !important',
      },
    },
  },
  columns: {
    display: 'flex',
    flexDirection: 'column',

    '& > div': {
      flex: 1,
    },
  },
  hidden: {
    '& > div': {
      height: '100%',
    },
  },
}));

const PromotionSlider = ({ data }) => {
  const cls = useStyle();

  return size(data) ? (
    <Box component="section" position="relative">
      <CarouselProvider
        isIntrinsicHeight
        totalSlides={data.length}
        dragEnabled={data.length > 1}
      >
        <Slider>
          {map(data, ({ slides = [] }, idx) => (
            <Slide index={idx} style={{ outline: 0 }}>
              <Container maxWidth="xl" disableGutters>
                <Grid container>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    className={cls.hidden}
                  >
                    <Hidden mdDown implementation="css">
                      <PromotionSliderBlockLarge
                        data={{
                          ...slides[0],
                          textSize: 'Large',
                        }}
                      />
                    </Hidden>
                    <Hidden lgUp>
                      <Grid container>
                        <Grid item sm={6} xs={12}>
                          <PromotionSliderBlockLarge
                            data={{
                              ...slides[0],
                              textSize: 'Large',
                            }}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <PromotionSliderBlockLarge
                            data={slides[1]}
                            titleTypographyProps={{
                              variant: 'h2',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    className={cls.columns}
                  >
                    <Hidden mdDown>
                      <PromotionSliderBlockLarge
                        data={slides[1]}
                        height="auto"
                        maxWidth="75%"
                        minHeight="0"
                        titleTypographyProps={{
                          variant: 'h2',
                        }}
                      />
                    </Hidden>
                    <Grid
                      container
                      style={{ height: 'auto' }}
                    >
                      <Grid item sm={6} xs={12}>
                        <PromotionSliderBlockLarge
                          data={slides[2]}
                          height="100%"
                          maxWidth="100%"
                          minHeight="0"
                          titleTypographyProps={{
                            variant: 'h3',
                          }}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <PromotionSliderBlockLarge
                          data={slides[3]}
                          height="100%"
                          maxWidth="100%"
                          minHeight="0"
                          titleTypographyProps={{
                            variant: 'h4',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Slide>
          ))}
        </Slider>
        {data.length > 1 && (
          <Box className={cls.dots} position="absolute">
            <DotGroup />
          </Box>
        )}
      </CarouselProvider>
    </Box>
  ) : null;
};

PromotionSlider.defaultProps = {
  data: [],
};

PromotionSlider.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default PromotionSlider;
