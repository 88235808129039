import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import VendorCard from '../../components/VendorCard';

const VendorLogoList = () => {
  const query = useStaticQuery(graphql`
    {
      allContentfulVendor(
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          contentful_id
          code
          name
        }
      }
    }
  `);

  return (
    <Grid container spacing={1} justify="center">
      {get(query, 'allContentfulVendor.nodes', []).map(
        ({ name, code }) => (
          <VendorCard
            code={code}
            key={code}
            name={name}
            size="md"
          />
        ),
      )}
    </Grid>
  );
};

export default VendorLogoList;
