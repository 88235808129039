import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import { map } from 'lodash';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RichText from 'gatsby-theme-q3/src/components/RichText';

const useStyle = makeStyles((theme) => ({
  mobile: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  mobileImg: {
    '& img': {
      objectFit: 'contain !important',
    },

    '& .gatsby-image-wrapper': {
      maxWidth: '30vw',
      width: 'auto',
      height: 180,
    },

    [theme.breakpoints.down('md')]: {
      '& .gatsby-image-wrapper': {
        margin: '0 auto',
        height: 150,
      },
    },
  },
}));

const HomepageCallToActions = ({
  title,
  callsToAction,
}) => {
  const cls = useStyle();

  return (
    <Box component="article" my={8}>
      <Container maxWidth="xl">
        <Container maxWidth="md">
          <Box mb={4}>
            <Typography
              align="center"
              variant="h1"
              component="h2"
            >
              {title}
            </Typography>
          </Box>
        </Container>
        <Grid container spacing={2} justifyContent="center">
          {map(callsToAction, (cta) => (
            <Grid
              key={cta.title}
              item
              lg={5}
              md={6}
              sm={12}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  sm
                  xs={12}
                  className={cls.mobileImg}
                >
                  <Image {...cta.image} />
                </Grid>
                <Grid item xs className={cls.mobile}>
                  <Typography variant="h4" component="h2">
                    {cta.title}
                  </Typography>
                  {cta.descriptionRt?.raw && (
                    <Box my={1}>
                      <RichText
                        json={JSON.parse(
                          cta.descriptionRt.raw,
                        )}
                      />
                    </Box>
                  )}
                  <Button
                    component={Link}
                    to={cta.buttonUrl || cta.to || '/'}
                    color="primary"
                    variant="outlined"
                  >
                    {cta.buttonText || 'Learn more'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

HomepageCallToActions.propTypes = {
  title: PropTypes.string.isRequired,
  callsToAction: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      buttonText: PropTypes.string,
      image: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
        }),
      }),
    }),
  ).isRequired,
};

export default HomepageCallToActions;
